<header>
  <mat-card class="distanza titolo">
    <mat-card-title>
      <div class="row">
        <div class="column_center">
          <img class="logo3" src="assets/img/logos3.png" />
        </div>
        <div class="column_sx">{{ env.title }}<br>FOR {{man.basic_info.name}}</div>
        <div class="column_dx">
          <button mat-raised-button class="distanza2" (click)="logout()">
            LOGOUT
          </button>
          <button mat-raised-button class="distanza4" (click)="profile()">
            MY PROFILE
          </button>
        </div>
      </div>
    </mat-card-title>
    <mat-card-subtitle class="subtitle">
      Welcome : {{ token.user.name }}, you have been logged in!
    </mat-card-subtitle>
  </mat-card>
</header>

<!-- <mat-card class="distanza">
  <mat-card-subtitle> TOKEN FROM KEYCLOAK </mat-card-subtitle>
  <mat-card-content>
    <pre class="scritta">{{ token | json }}</pre>
  </mat-card-content>
</mat-card> -->
<div id="comp-render" *ngIf="display">
  <mat-card class="distanza coloremenu">
    <mat-card-content>
      <div class="row">
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToOpenLots()"
          >
            LOTS
          </button>
        </div>
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToArchive()"
          >
            MY PROFILE
          </button>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

  <div id="comp-render" *ngIf="_opnelots">
    <app-lotview [token]="token"></app-lotview>
  </div>

  <div id="comp-render" *ngIf="_archive">
    <app-manufacturerformform [token]="token"></app-manufacturerformform>
  </div>
</div>

<!-- <div id="comp-render" *ngIf="_dna">
  <app-analysis-g  ></app-analysis-g>
</div>
<div id="comp-render" *ngIf="_patogeni">
  <app-patogeni-graph  ></app-patogeni-graph>
</div> -->
<footer>
  <mat-card class="distanza footer ">
    <mat-card-content>
      <img class="img" [src]="man.basic_info.logo" />
      <p>{{ env.bottom }}</p>
    </mat-card-content>
  </mat-card>
</footer>

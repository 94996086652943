import { Logger, LogLevel } from 'ask-logger';
const logger = Logger.getLogger('MyWebToken')
logger.set_level(LogLevel.DEBUG)
export class MyWebToken implements I_MyWebToken {
  origin: string;
  actualTime: Date;
  user: User;


  private constructor() {

  }

  public static of(o: any) {
    if (this.instanceOfI_MyWebToken(o)) {
      try {

        let a: I_MyWebToken = o as I_MyWebToken;
        let exit = new MyWebToken();
        exit.actualTime = new Date(o.actualTime);
        exit.origin = o.origin;
        exit.user = User.of(o.user)

        return exit;
      } catch (e) {
        logger.info(e)
        logger.info(o)
        throw Error("[MyWebToken][a] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      console.log(o)
      throw Error("[MyWebToken][b] Can not parse! it is not an instance of :" + JSON.stringify(o));
    }
  }


  public static instanceOfI_MyWebToken(object: any): object is I_MyWebToken {
    if (
      ("user" in object)
      && ("origin" in object)) {
      return true;
    } else {
      return false;
    }
  }

}

export class User implements I_User {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  scope: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  lab: string;
  man: string;
  family_name: string;
  email: string;


  private constructor() {
  }

  public static instanceOfI_User(object: any): object is I_User {
    if (
      ("man" in object)
      && ("name" in object)) {
      return true;
    } else {
      return false;
    }
  }

  public static of(o: any) {
    if (this.instanceOfI_User(o)) {
      try {

        let a: I_User = o as I_User;
        let exit = new User();
        exit.exp = a.exp
        exit.iat = a.iat
        exit.auth_time = a.auth_time
        exit.jti = a.jti
        exit.iss = a.iss
        exit.sub = a.sub
        exit.typ = a.typ
        exit.azp = a.azp
        exit.nonce = a.nonce
        exit.session_state = a.session_state
        exit.acr = a.acr
        exit.scope = a.scope
        exit.email_verified = a.email_verified
        exit.name = a.name
        exit.preferred_username = a.preferred_username
        exit.given_name = a.given_name
        exit.lab = a.lab
        exit.man = a.man
        exit.family_name = a.family_name
        exit.email = a.email


        return exit;
      } catch (e) {
        logger.error(e)
        throw Error("[a] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      logger.error("Not instance of ")
      throw Error("[b] Can not parse! :" + JSON.stringify(o));
    }
  }

}


export interface I_User {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  scope: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  lab: string;
  man: string;
  family_name: string;
  email: string;
}

export interface I_MyWebToken {
  origin: string;
  actualTime: Date;
  user: I_User;
}


import { NgxPaginationModule } from 'ngx-pagination';
import { ParserService } from './providers/parser.service';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { initializer } from './secure/kc-initializer';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ShowimageComponent } from './showimage/showimage.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { showDescriptionComponent } from './showDescription/showDescription.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QrCodeModule } from 'ng-qrcode';
import {   RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { MatIconModule } from '@angular/material/icon';
import { LotviewComponent } from './lotview/lotview.component';
import { QrcodegeneratorComponent } from './qrcodegenerator/qrcodegenerator.component';
import { StatusComponetComponent } from './status-componet/status-componet.component';
import { Generic_messageComponent } from './generic_message/generic_message.component';
import { LotformComponent } from './lotform/lotform.component';
import { ManufacturerformComponent } from './manufacturerform/manufacturerform.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LotviewComponent,
    StatusComponetComponent,
    Generic_messageComponent,
    QrcodegeneratorComponent,
    ShowimageComponent,
    ManufacturerformComponent,
    LotformComponent,
    showDescriptionComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    QrCodeModule,
    MatFormFieldModule,
    KeycloakAngularModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    HttpClientModule,
    MatIconModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RichTextEditorModule,
    MatNativeDateModule,
    MatSliderModule,
    NgxPaginationModule,

    MatSlideToggleModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    },

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

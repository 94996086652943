// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';


import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-qrcodegenerator',
  templateUrl: './qrcodegenerator.component.html',
  styleUrls: ['./qrcodegenerator.component.scss']
})
export class QrcodegeneratorComponent {

  modalTitle: string;
  modalMessage: string;
  modalType: ModalType = ModalType.INFO;
  // elementType = NgxQrcodeElementTypes.URL;
  // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value  = ""

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = "QR CODE";

    this.value = this.data;
    console.log(data)
  }

}
export enum ModalType {
  INFO = 'info',
  WARN = 'warn'
}

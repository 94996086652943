import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-showimage',
  templateUrl: './showimage.component.html',
  styleUrls: ['./showimage.component.css']
})
export class ShowimageComponent {

  modalTitle: string;
  modalMessage: string;
  modalType: ModalType = ModalType.INFO;

  logo = ""

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = "LOGO:";

    this.logo = this.data;
    console.log(data)
  }

}
export enum ModalType {
  INFO = 'info',
  WARN = 'warn'
}

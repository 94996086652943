import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { i_Lot4Analysis } from '../models/Lot4Analysis';

@Injectable({
  providedIn: 'root'
})
export class IntecomService {

  constructor() { }

  private _refresh_Form: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Message: BehaviorSubject<string> = new BehaviorSubject(null);
  private _special_Message: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Lot: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Manufacture: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Lab: BehaviorSubject<string> = new BehaviorSubject(null);
  formitem$ = this._refresh_Form.asObservable();
  refreshitem$ = this._refresh_Message.asObservable();
  specialDataForm$ = this._special_Message.asObservable();
  formLotItem$ = this._refresh_Form_Lot.asObservable();
  formManufactureItem$ = this._refresh_Form_Manufacture.asObservable();
  formLabItem$ = this._refresh_Form_Lab.asObservable();

  refreshList(message: string) {
    this._refresh_Message.next(message);
  }

  refreshForm(lot : i_Lot4Analysis) {
    this._refresh_Form.next(JSON.stringify(lot));
  }

  specialDataForm(data: any) {
    this._special_Message.next(JSON.stringify(data));
  }




  refreshLotForm(i_lot: i_Lot4Analysis) {
    this._refresh_Form_Lot.next(JSON.stringify(i_lot));
  }

}



import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { MyWebToken } from '../models/webtoken';
import { EventEmitter, Output } from '@angular/core';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
import { i_Manufacturer } from '../../../../s3qr-backend/models/Manufacturer';
import { ManufacturerProviderService } from '../providers/manufacturer-provider.service';
const LOGGER = Logger.getLogger('logged')
LOGGER.set_level(LogLevel.DEBUG)
export class logged {

  public token: MyWebToken;
  public name: string = "";
  public display: boolean = false;
  public man: i_Manufacturer;



  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService, protected manu_service: ManufacturerProviderService) {

    this.keycloakService.getToken().then((token_value: string) => {
      LOGGER.info('TOKEN :', token_value);
      this.comunication.loggedIn_data(token_value).then((result: string) => {
        try {
          // console.log(result)
          this.token = MyWebToken.of(result);
          this.getManufacturer(this.token.user.man);
          this.display = true;
        } catch (e) {
          LOGGER.error(' Error2 :', e);
        }

      }).catch((e) => {
        LOGGER.error(' Error :', e);
      });
    })

  }


  getManufacturer(id: string) {
    this.manu_service.getManufacturerById(id).then((_man: i_Manufacturer) => {
      this.man = _man;
      LOGGER.error("--MANU-------------------------")
      LOGGER.info(this.man)
    }).catch((e) => {
      LOGGER.error("---------------------------")
      LOGGER.error(e)

    })
  }
}

<mat-card class="distanza">
  <mat-card-title>EDIT LOT : </mat-card-title>
  <mat-card-content>



    <form [formGroup]="registerForm" (ngSubmit)="addlot()">
      <div class="row">
        <div class="column_dx">

            <mat-label>LOT Identifier: </mat-label>
            <mat-label>{{lot4Analysis.lot}}</mat-label>
        </div>
        <div class="column_dx">

            <mat-label>Category: </mat-label>
            <mat-label>{{lot4Analysis.category}}</mat-label>


        </div>
      </div>
   <div class="row">
        <div class="column_dx">
           <mat-label>Creation Date: </mat-label>
            <mat-label>{{lot4Analysis.creation_date | date: "yyyy-MM-dd"}}</mat-label>

        </div>
        <div class="column_dx">

            <mat-label>Expiration Date: </mat-label>
            <mat-label>{{lot4Analysis.expiration_date | date: "yyyy-MM-dd"}}</mat-label>

        </div>
      </div>
      <div class="row">
        <mat-label class="forminput3">Lot Description</mat-label>
        <div class="editor">
          <ejs-richtexteditor
            formControlName="description"
            required
            [toolbarSettings]="tools"
            [(ngModel)]="lot4Analysis.desc"
            placeholder="Lot Description"
          ></ejs-richtexteditor>
        </div>


      </div>


        <div class="row">
          <div class="preforminput">
            <button
              *ngIf="lot4Analysis.image"
              mat-icon-button
              type='button'
              color="warn"
              matTooltip="Show Lot Image"
              class="minibutton"
              (click)="showimage(lot4Analysis.image)"
            >
              <mat-icon>image</mat-icon>
            </button>
          </div>
          <div class="forminput">
          <mat-label  >Edit Uploading a new Lot Image (optional)</mat-label><br>

            <input
              type="file"
              (change)="getLogo($event)"
              id="filesLogo"
              #filesLogo
              accept="image/*"
            />
          </div>
        </div>


       <button  mat-raised-button color="warn" class="distanza2">Edit Lot</button>
      <div *ngIf="submitted && f.expiration_date.errors">
        <div *ngIf="f.expiration_date.errors.range">
          Creation Date should be before Expiration Date!!!
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { ManufacturerProviderService } from '../providers/manufacturer-provider.service';
import { logged } from '../support/logged';

import { Logger, LogLevel } from 'ask-logger';
import { i_Manufacturer } from '../models/Manufacturer';
const LOGGER = Logger.getLogger('logged')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent extends logged implements OnInit {


  result: any = {};
  env: any;
  _opnelots: boolean = false;
  _archive: boolean = false;
  _dna: boolean = false;
  _patogeni: boolean = false;
  man: i_Manufacturer;


  writeOrDie(text: any) {
    LOGGER.error(text)
  }


  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService, protected manu_service: ManufacturerProviderService ) {
    super(keycloakService, comunication, manu_service);
    this.env = environment;

  }

  ngOnInit(): void {
    LOGGER.info("*************************")

  }

  profile() {
    window.open(environment.manage_user_url);
  }

  goToOpenLots() {
    LOGGER.info("toddddken : ", this.token.user)
    this._opnelots = true;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
  }

  goToArchive() {
    this._opnelots = false;
    this._archive = true;
    this._dna = false;
    this._patogeni = false;
  }
  goToDNA() {
    this._opnelots = false;
    this._archive = false;
    this._dna = true;
    this._patogeni = false;
  }
  goToPatogeni() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = true;
  }

logout() {
  this.keycloakService.logout();
}

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Manufacturer } from '../models/Manufacturer';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerProviderService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }

  public async getManufacturerById(id: string): Promise<any> {

    return this.http.get(environment.main_url + environment.backend_getManufacturerById + id).pipe(timeout(environment.timeout)).toPromise();

  }


  public async addManufacturer(l: Manufacturer): Promise<any> {
    console.log(l)

    return this.http.post(environment.main_url + environment.addManufacturer, l).toPromise();

  }

}

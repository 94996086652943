export class Basic_info implements i_Basic_info {

	name: string;
	logo?: string;
	address: string;
	phone?: string;
	website?: string;
	email: string;

  constructor() {
  }

	private validateState() {
		if (!this.name) throw new Error("Name is missing");
    if (!this.address) throw new Error("Address is missing");
    if (!this.email) throw new Error("Email is missing");

	}
	public static instanceOfBasic_info(object: any): object is i_Basic_info {
		if (("name" in object)
			&& ("email" in object)
			&& ("address" in object)) {
			return true;
		} else {
			return false;
		}
	}
	public static of(o: any): Basic_info {
		if (this.instanceOfBasic_info(o)) {
			try {
        let a: i_Basic_info = o as i_Basic_info;
        let exit = new Basic_info();
        exit.address = a.address;
        exit.email = a.email;
        exit.logo = a.logo;
        exit.name = a.name;
        exit.phone = a.phone;
        exit.website = a.website;
        exit.validateState()
				return exit;
			} catch (e) {
				throw "Can not parse! :" + o;
			}
		} else {
			throw "Can not parse! :" + o;
		}
	}




}




export interface i_Basic_info {


	name: string;
	logo?: string;
	address: string;
	phone?: string;
	website?: string;
	email: string;

}

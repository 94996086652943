
export class Generic_Report implements i_Generic_Report {


  _id: string;
  lot_id: string;
  lab_id: string;
  insert_date: Date;
  title_result: string;
  text_result: string;
  image: string;
  other_result: any;

  public validateState() {

    if (!this.lot_id) throw "lot_id is missing";
    if (!this._id) throw "_id is missing";
    if (!this.text_result) throw "text_result is missing";

  }

  public showDNA(): boolean {
    return this.lab_id == "CNR-IREA";
  }

  public static instanceOfi_Generic_Report(object: any): object is i_Generic_Report {
    let exit = true;
    if (!("lot_id" in object)) {
      exit = false;

    }
    if (!("text_result" in object)) {
      exit = false;

    }
    if (!("lab_id" in object)) {
      exit = false;

    }

    return exit;

  }
  public static of(o: any): Generic_Report {
    if (this.instanceOfi_Generic_Report(o)) {
      try {
        let a: i_Generic_Report = o as i_Generic_Report;
        let exit = new Generic_Report()
        exit._id = a._id
        exit.lot_id = a.lot_id
        exit.lab_id = a.lab_id
        exit.insert_date = new Date(a.insert_date)
        exit.title_result = a.title_result
        exit.text_result = a.text_result
        exit.image = a.image
        exit.other_result = a.other_result
        exit.validateState()
        return exit;
      } catch (e) {
        throw Error("[a] Can not parse! :" + o);
      }
    } else {
      throw Error("[b] Can not parse! :" + o);
    }
  }




}

export interface i_Generic_Report {
  _id: string

  lot_id: string;

  lab_id: string

  insert_date: Date;

  title_result: string

  text_result: string;

  image: string;

  other_result: any;
}

<div *ngIf="showeditor">
  <app-lotform></app-lotform>
</div>
<mat-card class="distanza">
  <mat-card-title>LOTS LIST : </mat-card-title>
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
  <mat-card-content
    class="cella example-card mat-elevation-z6"
    *ngFor="let lot of lots | paginate: { itemsPerPage: 10, currentPage: p }"
  >
    <div class="row">
      <div class="column_sx">
        <i>ID : </i> {{ lot._id }} <br />
        <i>Lot :</i> {{ lot.lot }} <br />
        <i>Category :</i> {{ lot.category }} <br />
        <i>Manufacturer :</i> {{ lot.manufacturer.basic_info.name }} <br />
      </div>
      <div class="column_center_sx">
        <i>Creation Date :</i> {{ lot.creation_date | date: "yyyy-MM-dd" }}
        <br />
        <i>Expiration Date :</i> {{ lot.expiration_date | date: "yyyy-MM-dd" }}
        <br />
        <i>Average Vote :</i> {{ lot.average_rate }} <br />
        <i>Voters :</i> {{ lot.voters }} <br />
      </div>
      <div class="column_center_dx">
        <div class="row">
          <div class="column">
            <button
              mat-icon-button
              color="warn"
              class="minibutton"
              matTooltip="Show QR-Code"
              (click)="testQR(lot._id)"
            >
              <mat-icon>qr_code_2</mat-icon>
            </button>
          </div>
          <div class="column">
            <button
              *ngIf="lot.image"
              mat-icon-button
              color="warn"
              matTooltip="Show Lot Image"
              class="minibutton"
              (click)="showimage(lot.image)"
            >
              <mat-icon>image</mat-icon>
            </button>
          </div>
          <div class="column">
            <button
              mat-icon-button
              color="warn"
              class="minibutton"
              matTooltip="Show Status"
              (click)="getStatus(lot.getStatu())"
            >
              <mat-icon>event_note</mat-icon>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <button
              *ngIf="lot.rate_enabled"
              mat-icon-button
              class="minibutton"
              matTooltip="Disable Rating"
              (click)="changeRating(lot)"
            >
              <mat-icon>star_rate</mat-icon>
            </button>
            <button
              *ngIf="!lot.rate_enabled"
              mat-icon-button
              color="warn"
              class="minibutton"
              matTooltip="Enable Rating"
              (click)="changeRating(lot)"
            >
              <mat-icon>star_rate</mat-icon>
            </button>
          </div>
          <div class="column">
            <button
              mat-icon-button
              color="warn"
              class="minibutton"
              matTooltip="Edit Lot settings"
              (click)="editLot(lot)"
            >
              <mat-icon>settings</mat-icon>
            </button>
          </div>
          <div class="column">
            <button
              *ngIf="lot.canBeDeleted()"
              mat-icon-button
              color="warn"
              class="minibutton"
              matTooltip="Delete Lot"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <i>Labs :</i>
      {{ lot.getLabList() }}
    </div>
    <!-- <div class="row" *ngIf="lot.desc">
      <i>Description :</i> <br />
      {{ lot.desc }}
    </div> -->
  </mat-card-content>
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
</mat-card>

<div class="custom-dialog {{ modalType }}">
  <mat-dialog-content >
    <h2>ANALYSIS STATUS :</h2>
    <div class="row" *ngFor="let status of state">
      <div class="column">
        <p>{{ status.lab_name }}</p>
      </div>
      <div class="column">
        <p *ngIf="status.compiled">
          {{ status.pub_date | date: "yyyy-MM-dd" }}
        </p>
        <p *ngIf="!status.compiled">NA</p>
      </div>
      <div class="column">
        <span *ngIf="status.compiled" class="material-icons"> verified </span>
        <span *ngIf="!status.compiled" class="material-icons">highlight_off</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">

    <button mat-raised-button color="warn" [mat-dialog-close]="true">OK</button>
  </mat-dialog-actions>
</div>

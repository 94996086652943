<mat-card class="distanza">
  <mat-card-title>EDIT MY MANUFACTURER PROFILE : </mat-card-title>
  <mat-card-content>
    <form [formGroup]="registerForm" (ngSubmit)="addManufacturer()">
      <div class="row">
        <mat-form-field appearance="standard" class="forminput">
          <mat-label>Manufacturer Name</mat-label>
          <input
            matInput
            formControlName="name"
            [(ngModel)]="manufacturer.basic_info.name"
            placeholder="Laboratory Name"
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </mat-form-field>
      </div>

      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Manufacturer Email</mat-label>
            <input
              matInput
              formControlName="email"
              [(ngModel)]="manufacturer.basic_info.email"
              placeholder="Laboratory Email"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Manufacturer Phone</mat-label>
            <input
              matInput
              formControlName="phone"
              [(ngModel)]="manufacturer.basic_info.phone"
              placeholder="Laboratory Phone"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Manufacturer Address</mat-label>
            <input
              matInput
              formControlName="address"
              [(ngModel)]="manufacturer.basic_info.address"
              placeholder="Laboratory Address"
            />
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">Address is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Manufacturer Website Url</mat-label>
            <input
              matInput
              formControlName="website"
              [(ngModel)]="manufacturer.basic_info.website"
              placeholder="Laboratory Website Url"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
         <div class="preforminput">
            <button
              *ngIf="manufacturer.basic_info.logo"
              mat-icon-button
              type='button'
              color="warn"
              matTooltip="Show Lot Image"
              class="minibutton"
              (click)="showimage(manufacturer.basic_info.logo)"
            >
              <mat-icon>image</mat-icon>
            </button>
          </div>
        <div class="forminput">
          <mat-label>Edit Uploading a new Lab logo</mat-label><br>

          <input
            type="file"
            (change)="getLogo($event)"
            id="filesLogo"
            #filesLogo
            accept="image/*"
          />
        </div>
      </div>


      <button
        mat-raised-button
        class="distanza2"
        color="warn"

      >
        Edit Manufacturer
      </button>
    </form>
  </mat-card-content>
  <!-- <button mat-raised-button class="distanza2" (click)="test()">
            TEST
          </button> -->
</mat-card>

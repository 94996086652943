import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class KcConnectService {



  private static LOG_INFO = environment.main_url + '/inizialize/';

  constructor(private http: HttpClient) {

  }



  public async loggedIn_data(token: string): Promise<any> {

    let httpOptions = {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    return this.http.get(KcConnectService.LOG_INFO).pipe(timeout(3000)).toPromise();

  }




}

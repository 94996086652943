import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos

export const environment = {

  production: false,
  envName: 'local',
  keycloak: {
    issuer: 'https://auth.s3qr.it/auth',
    realm: 'S3qr',
    requireHttps: true,
    clientId: 's3qr-manufacturer'
  },
  addManufacturer: "/addManufacturer",
  // main_url: 'https://www.fhffcapp.it/s3edge/',
  timeout: 10000,
  main_url: 'https://www.fhffcapp.it/s3edgemanager',
  title: "S3QR.IT MANUFACTURER MODULE",
  bottom: "© Work in progress CNR-IREA 2020 - v.0.0.1a",
  manage_user_url: "https://auth.s3qr.it/auth/realms/S3qr/account/applications",
  getLot4Analysis: "/getLot4Analysis",
  backend_getGenericRepoByLot: "/getGenericRepoByLot/",
  backend_getGenericRepoByLab: "/getGenericRepoByLab/",
  backend_addGenericRepo: "/addGenericRepo",
  backend_getLot4AnalysisByLab: "/getLot4AnalysisByLab/",
  backend_getManufacturerById: "/getManufacturerById/",
  backend_getLot4AnalysisByManufacturer: "/getLot4AnalysisByManufacturer/",
  success: "Success!",
  error: "Error!",
  urlqr: "https://www.s3qr.it/home/",
  warning: "Warning!",
  refreshlablist: "REFRESH_LAB_LIST",
  backend_getPendingLot4AnalysisByLab: "/getPendingLot4AnalysisByLab/",
  backend_getCompletedLot4AnalysisByLab: "/getCompleteLot4AnalysisByLab/",
  update_Lot: "/addGenericRepo",
  reference_graph : ["IPC","RP"],
  dnaEnable: ['CNR-IREA','CNR-IBBA'],

};

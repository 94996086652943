import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalType } from '../showimage/showimage.component';
@Component({
  selector: 'app-showDescription',
  templateUrl: './showDescription.component.html',
  styleUrls: ['./showDescription.component.scss']
})
export class showDescriptionComponent   {

  modalTitle: string;
  modalMessage: string;
  modalType: ModalType = ModalType.INFO;

  desc = ""

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = "Description:";

    this.desc = this.data;
    console.log(data)
  }

}


import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { logged } from '../support/logged';
import { environment } from '../../environments/environment';
import { IntecomService } from '../providers/intecom.service';
import { i_Manufacturer, Manufacturer } from '../models/Manufacturer';
import { ManufacturerProviderService } from '../providers/manufacturer-provider.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Generic_messageComponent } from '../generic_message/generic_message.component';
import { Subscription } from 'rxjs';
import { MyWebToken } from '../models/webtoken';
import { Logger, LogLevel } from 'ask-logger';
import { ShowimageComponent } from '../showimage/showimage.component';
const LOGGER = Logger.getLogger('ManufacturerformComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-manufacturerformform',
  templateUrl: './manufacturerform.component.html',
  styleUrls: ['./manufacturerform.component.css']
})
export class ManufacturerformComponent implements OnInit {

  @Input()
  token: MyWebToken;
  env: any;
  manufacturer: Manufacturer = new Manufacturer();
  registerForm: FormGroup;
  submitted = false;
  isEdit: boolean = false;
  subscription: Subscription;
  @Output() refreshlistlabEvent = new EventEmitter<string>();
  @ViewChild("filesLogo") filesLogo: ElementRef;

  constructor(public dialog: MatDialog,protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    private formBuilder: FormBuilder,
    protected manufacturer_service: ManufacturerProviderService,
    protected manu_service: ManufacturerProviderService,
    private intercom: IntecomService) {

    this.env = environment;
  }

  getManufacturer(id: string) {
    this.manu_service.getManufacturerById(id).then((_man: i_Manufacturer) => {
      this.manufacturer = Manufacturer.of(_man);
      LOGGER.error("--MANU-------------------------")
      LOGGER.info(this.manufacturer  )
    }).catch((e) => {
      LOGGER.error("---------------------------")
      LOGGER.error(e)

    })
  }


  sendRefreshEvent() {
    this.refreshlistlabEvent.emit(this.env.refreshlablist);
  }


  ngOnInit(): void {


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      logo: [],
      address: ['', Validators.required],
      phone: [],
      website: [],
      email: ['', [Validators.required, Validators.email]]
    });
    this.getManufacturer(this.token.user.man);

  }

  addManufacturer() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    this.manufacturer_service.addManufacturer(this.manufacturer).then((res: boolean) => {
      console.log(res)

      if (res) {
        this.intercom.refreshList(this.env.refreshlablist);
        dialogConfig.data = {
          title: environment.success,
        };
        this.submitted = false;
        this.filesLogo.nativeElement.value = "";
        this.getManufacturer(this.token.user.man);
        this.isEdit = false;
      } else {
        dialogConfig.data = {
          title: environment.error,
        };
      }
      this.dialog.open(Generic_messageComponent, dialogConfig);
    }).catch((err) => {
      console.log(err)
      dialogConfig.data = {
        title: environment.error,
      };
      this.dialog.open(Generic_messageComponent, dialogConfig);
    })
  }
  get f() { return this.registerForm.controls; }
  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }


  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  readThisLogo(inputValue: any): void {
    var file: File = inputValue.files[0];

    this.getBase64(file).then(result => {
      console.log(result.toString().length);
      this.manufacturer.basic_info.logo = result.toString();
    });
  }

  getLogo($event) {

    this.readThisLogo($event.target);
    console.log("-|4->", this.filesLogo.nativeElement.files.length);
    console.log("-|4->", this.filesLogo.nativeElement.files[0].name);

  }



}

import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { i_Status } from '../models/Lot4Analysis';
@Component({
  selector: 'app-status-componet',
  templateUrl: './status-componet.component.html',
  styleUrls: ['./status-componet.component.css']
})
export class StatusComponetComponent   {

  state: i_Status[];
  modalType: ModalType = ModalType.INFO;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<StatusComponetComponent>) {
    this.state = data;
    this.modalType = data.type;

    console.log(data)

  }



  onNoClick(): void {
    this.dialogRef.close();
  }

}
export enum ModalType {
  INFO = 'info',
  WARN = 'warn'
}
